// The burger menu works with a checkbox, when checked the contents are visible, when unchecked, they are hidden
const burgerMenuCheckbox = document.querySelector('#checkbox');
const mainContent = document.querySelector('main');

mainContent.addEventListener('click', () => {
  if (burgerMenuCheckbox.checked) {
    burgerMenuCheckbox.checked = false;
  }
});

